<template>
	<div class="animated fadeIn">
			<b-row>
				<b-col cols=3></b-col>
				<b-col cols=6>
					<b-card v-if="envelope">
						<b-row>
						<b-col>
							<b-card header="Prejemnik">
								<b-row class="mb-2">
									<b-col cols=3>
										<b>Naziv: </b>
									</b-col>
									<b-col>
										{{ envelope.receiver_name }}
									</b-col>
								</b-row>
								<b-row class="mb-2">
									<b-col cols=3>
										<b>Naslov: </b>
									</b-col>
									<b-col>
										{{ envelope.receiver_street }}, {{ envelope.receiver_city }} {{ envelope.receiver_country }}
									</b-col>
								</b-row>
								<b-row class="mb-2">
									<b-col cols=3>
										<b>TRR: </b>
									</b-col>
									<b-col>
										{{ envelope.receiver_mailbox }}
									</b-col>
								</b-row>
								<b-row class="mb-2">
									<b-col cols=3>
										<b>DŠ: </b>
									</b-col>
									<b-col>
										{{ envelope.receiver_identifier }}
									</b-col>
								</b-row>
								<b-row class="mb-2">
									<b-col cols=3>
										<b>Telefon: </b>
									</b-col>
									<b-col>
										{{ envelope.receiver_phone }}
									</b-col>
								</b-row>
								<b-row class="mb-2">
									<b-col cols=3>
										<b>Email: </b>
									</b-col>
									<b-col>
										{{ envelope.receiver_email_id }}
									</b-col>
								</b-row>
							</b-card>
						</b-col>
						<b-col>
							<b-card header="Pošiljatelj">
								<b-row class="mb-2">
									<b-col cols=3>
										<b>Naziv: </b>
									</b-col>
									<b-col>
										{{ envelope.sender_name }}
									</b-col>
								</b-row>
								<b-row class="mb-2">
									<b-col cols=3>
										<b>Naslov: </b>
									</b-col>
									<b-col>
										{{ envelope.sender_street }}, {{ envelope.sender_city }} {{ envelope.sender_country }}
									</b-col>
								</b-row>
								<b-row class="mb-2">
									<b-col cols=3>
										<b>TRR: </b>
									</b-col>
									<b-col>
										{{ envelope.sender_mailbox }}
									</b-col>
								</b-row>
								<b-row class="mb-2">
									<b-col cols=3>
										<b>DŠ: </b>
									</b-col>
									<b-col>
										{{ envelope.sender_identifier }}
									</b-col>
								</b-row>
								<b-row class="mb-2">
									<b-col cols=3>
										<b>Telefon: </b>
									</b-col>
									<b-col>
										{{ envelope.sender_phone }}
									</b-col>
								</b-row>
								<b-row class="mb-2">
									<b-col cols=3>
										<b>Email: </b>
									</b-col>
									<b-col>
										{{ envelope.sender_email_id }}
									</b-col>
								</b-row>
							</b-card>
						</b-col>
						</b-row>
						<b-row>
							<b-col>
								<b-card :header="'Eračun z referenco ' + envelope.creditor_structured_reference" >
									<b-row class="mb-2">
										<b-col cols=3>
											<b>Namen: </b>
										</b-col>
										<b-col>
											{{ envelope.additional_remittance_information }}
										</b-col>
									</b-row>
									<b-row class="mb-2">
										<b-col cols=3>
											<b>Znesek: </b>
										</b-col>
										<b-col>
											{{ envelope.amount | hr_money }} {{ envelope.currency }}
										</b-col>
									</b-row>
								</b-card>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								{{ envelope }}
							</b-col>
						</b-row>
					</b-card>
					<b-card v-show="message.text != null">
						<CAlert v-show="message.text != null" :color="message.type">
							{{ message.text }}
						</CAlert>
					</b-card>
				</b-col>
				<b-col cols=3></b-col>
			</b-row>
	</div>
</template>

<script>
import { ENVELOPE_GET } from "@/store/actions/ebills";

export default {
    name: 'EnvelopeXMLpreview',
    components: {
    },
	data () {
		return {
			message: {
				type: null,
				text: null
			},
			ALERT_TYPE_SUCCESS: 'success',
			ALERT_TYPE_ERROR: 'danger',
			isLoadingEnvolope: false,
			envelope: null,
			xmlPlainText: ''
		}
	},
    computed: {
    },
    methods: {
		showAlert: function (message, type, seconds) {
			if (message && type && seconds) {
				this.message.type = type;
				this.message.text = message;
				setTimeout(() => {
					this.showAlert();
				}, seconds * 1000);
			} else if (message && type) {
				this.message.type = type;
				this.message.text = message;
			} else if (message) {
				this.message.type = 'primary';
				this.message.text = message;
			} else {
				this.message.type = null;
				this.message.text = null;
			}
		},
		getEnvolope: function () {
			this.isLoadingEnvolope = true;
			if (this.$route.query.envelopeId != null && this.$route.query.envelopeId > 0) {
				this.$store.dispatch(ENVELOPE_GET, { id: this.$route.query.envelopeId })
					.then((responseData) => {
						this.isLoadingEnvolope = false;
						this.envelope = responseData.envelope;
						console.log(responseData);
					})
					.catch(error => {
						this.isLoadingEnvolope = false;
						console.error(error);
						if(error.response && error.response.data && error.response.data.code) {
							this.showAlert('Napaka pri pridobivanju ovojnice! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						} else {
							this.showAlert('Napaka pri pridobivanju ovojnice! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
						}
					}); 
			} else {
				this.showAlert('Manjka ID ovojnice!', this.ALERT_TYPE_ERROR);
			}
		}
    },
    created: function () {
		this.getEnvelope();
    }
}
</script>
<style scoped>
</style>
<style>
</style>